module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.18.0_jiti@2.3.1___nclrmxnr4oc53xo4ctbtzplnru/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-P6ZYM4KZ81"],"gtagConfig":{"anonymize_ip":true,"send_page_view":false,"allow_ad_features":false},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.18.0_jiti@2.3.1___re_ywooeghh7y7kqxunrauvxlwd6e/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vincent Reynaud","short_name":"Vincent Reynaud","start_url":"/","background_color":"#0a0917","theme_color":"#0a0917","display":"standalone","icon":"static/favicon.png","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"014158be0274a7df08b71e0b8496cc5b"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.18.0_jiti@2.3.1___rea_x4xlhpbwyiv37crb6u4cb5zl54/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.18.0_jiti@2.3.1___react-dom@18.3.1_react@18.3.1__r_ggyiut3swrh5iamikwesd2ke4u/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
